import React from "react"
import { graphql, Link } from "gatsby"

import Bio from "../components/bio"
import Layout from "../components/layout"
import { FaEnvelope, FaGithub, FaTwitter } from "react-icons/fa"

const HomePage = ({ data, location }) => {
  const posts = data.allMarkdownRemark.nodes
  const github = data.site.siteMetadata.social.github
  const twitter = data.site.siteMetadata.social.twitter

  return (
    <Layout location={location} title="Home">
      <Bio />
      <div className="navbar navbar-main">
        <div className="navbar-items">
          <Link className="navbar-item" to="/projects">Projects</Link>
          <Link className="navbar-item" to="/publications">Publications</Link>
        </div>
        {/*<li><Link>CV</Link></li>*/}
      </div>
      <div className="navbar navbar-main">
        <a className="navbar-icon" href={`https://github.com/${github}`} target="_blank" rel="noreferrer"><FaGithub/></a>
        <a className="navbar-icon" href={`https://twitter.com/${twitter}`} target="_blank" rel="noreferrer"><FaTwitter/></a>
        <a className="navbar-icon" href={`mailto:marten@martensigwart.com`} target="_blank" rel="noreferrer"><FaEnvelope/></a>
        {/*<li><Link>CV</Link></li>*/}
      </div>
      {/*<ol style={{ listStyle: `none` }}>*/}
      {/*  {posts.map(post => {*/}
      {/*    const title = post.frontmatter.title || post.fields.slug*/}

      {/*    return (*/}
      {/*      <li key={post.fields.slug}>*/}
      {/*        <article*/}
      {/*          className="post-list-item"*/}
      {/*          itemScope*/}
      {/*          itemType="http://schema.org/Article"*/}
      {/*        >*/}
      {/*          <header>*/}
      {/*            <h2>*/}
      {/*              <Link to={post.fields.slug} itemProp="url">*/}
      {/*                <span itemProp="headline">{title}</span>*/}
      {/*              </Link>*/}
      {/*            </h2>*/}
      {/*            <small>{post.frontmatter.date}</small>*/}
      {/*          </header>*/}
      {/*          <section>*/}
      {/*            <p*/}
      {/*              dangerouslySetInnerHTML={{*/}
      {/*                __html: post.frontmatter.description || post.excerpt,*/}
      {/*              }}*/}
      {/*              itemProp="description"*/}
      {/*            />*/}
      {/*          </section>*/}
      {/*        </article>*/}
      {/*      </li>*/}
      {/*    )*/}
      {/*  })}*/}
      {/*</ol>*/}
    </Layout>
  )
}

export default HomePage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        social {
          twitter
          github
        }
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          description
        }
      }
    }
  }
`

/**
 * Bio component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

const Bio = () => {
  const data = useStaticQuery(graphql`
    query BioQuery {
      avatar: file(relativePath: { eq: "profile-pic.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: FIXED, height: 150)
        }
      }
      site {
        siteMetadata {
          author
        }
      }
    }
  `)

  // Set these values by editing "siteMetadata" in gatsby-config.js
  const author = data.site.siteMetadata?.author

  const avatar = data?.avatar?.childImageSharp?.gatsbyImageData

  return (
    <div className="bio">
      {avatar && (
        <GatsbyImage
          image={avatar}
          alt={author || ``}
          className="bio-avatar"
          style={{
            borderRadius: `50%`,
          }}
        />
      )}
      <p className="text-center">
        Fullstack software engineer with a passion for effective UI/UX.<br/>
        Currently helping the world build better charts at <a href={"https://www.datawrapper.de/"} target={"_blank"} rel={"noreferrer"}>Datawrapper</a>.<br/>
        Based in Berlin, Germany.
      </p>
    </div>
  )
}

export default Bio
